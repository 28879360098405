import React from "react";
import { PageTitle } from "../../pageTitle/PageTitle";
import "./style.css";
import logo from './logo-menu.png';
import "./bootstrap.min.css";
import logo2 from './profile-img.jpeg';

const Informacion = () => {
  return (<body>
    
     
     <section id="hero" className="d-flex flex-column justify-content-center"><center> 
    <h1>Inmobiliaria</h1></center> <br/>
    <div className="container" data-aos="zoom-in" data-aos-delay="100"> 
    <img className="img-fluid" src={logo} />
           <br/>
        
     
    </div>
    
  </section>


    
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Quién soy</h2>
         
        </div>

        <div className="row"><h3>Ludmila Rosas</h3>
        
      
      <p className="fst-italic">"Ventas, Alquileres Temporarios, Alquileres Anuales, Inversiones,Tasaciones,Administración de Propiedades"</p>
          <div className="col-lg-4">
              <img className="img-fluid" src={logo2} alt="" width="500" height="500"align="right"/>
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content">
            <h3>MARTILLERA Y CORREDORA PÚBLICA NACIONAL. <br/> <br/> ADMINISTRADORA DE PROPIEDADES.<br/></h3>
            <p className="fst-italic">
              Mi nombre es Ludmila, nací en General Conesa el pueblo más pequeño de la Pcia. de Buenos Aires y me establecí en San Bernardo desde muy joven, mis comienzos en el rubro se dieron de manera natural desde mi adolescencia dado que  el desarrollo inmobiliario forma parte de mi familia desde hace muchos años.<br/> <br/>
En el año 2018, luego de cursar la carrera en la Facultad Siglo XXI, recibí la Matrícula de Martillera y Corredora Pública Nacional, dando pie al comienzo de mi propio emprendimiento. <br/><br/>

El enfoque principal de Ludmila Rosas Inmobiliaria es establecer una relación de confianza y familiaridad con el cliente, atendiendo sus necesidades y respondiendo las inquietudes de manera profesional brindando un servicio de alto nivel.<br/><br/>

Contamos con amplios conocimientos en el mercado inmobiliario actual y una visión amplia para que el asesoramiento a nuestros clientes sea integral, dedicandonos además a una  constante capacitación para la ampliación y mejora de nuestros servicios.<br/><br/>

Acompañamos a compradores y vendedores desde sus primeros pasos hasta el momento de la escrituración.<br/><br/> Nuestra misión es facilitar la comunicación entre las partes innovando con herramientas del marketing inmobiliario, generando un negocio exitoso y asegurando como resultado la mejor experiencia.
 </p>
            
             <img className="img-fluid" src={logo} alt="" width="300" height="300"align="right"/><br/><br/>
           
           <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d799.8599277798683!2d-56.67775824536897!3d-36.68797028650622!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9722541b2aec4895!2sLudmila%20Rosas%20Inmobiliaria!5e0!3m2!1ses!2sar!4v1622701304286!5m2!1ses!2sar"
              width="100%"
              height="290"
              frameBorder="0"
              style={{ border: 0 , margin: 0,  }}
              allowFullScreen=""
              justify-content= "center"
              aria-hidden="false"
              tabIndex="0"
             
            />
          </div>
        </div>

      </div>
       </section>

     
    
    </body>

  );
};

export default Informacion;
