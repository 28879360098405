import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import Publicar from '../publicar/Publicar';

const EditarPropiedad = ()=>{
  const { id } = useParams();
  const [doc, setDoc] = useState({});
  useEffect(() => {
    firestore
      .collection("estates")
      .doc(id)
      .get()
      .then((e) => setDoc(e.data()));
      console.log("documento: ", doc)
  }, [id]);

  const editInitialState = {
    propCode: doc.propCode ? doc.propCode : "", 
    title: doc.title,
    description: doc.description,
    type: doc.type,
    comercialStatus: doc.comercialStatus,
    price: doc.price,
    images: doc.images,
    characteristics: doc.characteristics,
    attributes: doc.attributes,
    featured: doc.featured,
    rentalFeatured: doc.rentalFeatured,
    slider: doc.slider,
    revisorMesagge: doc.revisorMesagge,
    location: doc.location,
    video_id: doc.video_id,
  };

  console.log(editInitialState);
  
  return doc.location ? (
    <Publicar editar={true} propiedad={doc} editInitialState={editInitialState} idProp={id} />
  ) : (
    <div className="c-loader" />
  );
}

export default EditarPropiedad;