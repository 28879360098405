import firebase from "firebase/app";
import 'firebase/database';
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "dotenv/config";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
});


export const firestore = firebase.firestore();
export const database = firebase.database();
export const storage = firebase.storage();

export const auth = firebase.auth();
