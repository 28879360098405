import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import mapboxgl from "mapbox-gl";
import ProductSlider from "../../Slider/ProductSlider";
import logo from "../../menu/logo-menu.png";
import "./propiedad.css";
import { icons } from "../../Slider/Slider";
import { IconContext } from "react-icons";
import wh from "../propiedad/wh.png";
import { GoCheck } from "react-icons/go";

mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;


const Propiedad = (_props) => {
  const [doc, setDoc] = useState({});
  const { id } = useParams();
  useEffect(() => {
    firestore
      .collection("estates")
      .doc(id)
      .get()
      .then((e) => setDoc(e.data()));
  }, [id]);

  return doc.location ? (
    <div className="inm-div">
      <InmuebleBody document={doc} />
    </div>
  ) : (
    <div className="c-loader" />
  );
};

const InmuebleBody = (props) => {
  const {
    agent,
    attributes,
    characteristics,
    comercialStatus,
    description,
    images,
    location,
    price,
    title,
    type,
    video_id,
    propCode
  } = props.document;
 
  const { addressLine, city, country, neighborhood, state } = location;
  return (
    <>
      <div className="inm-spacer" />
      <div className="inm-title-div">
        <div className="inm-title-left">
          <h6 className="inm-pretitle"><b>{`${type} en ${comercialStatus}`}</b></h6><br></br>
          <h1 className="inm-title">{title}</h1>
        {propCode ? <h6 className="inm-subtitle"> <b>Código de propiedad:</b> {`${propCode}`}</h6> : null}
          <h3 className="inm-subtitle">{`${addressLine}, ${neighborhood}, ${city}, ${state}, ${country}`}</h3>
        </div>
        <div className="inm-title-right">
          <h5 className="inm-type"><b>{comercialStatus}</b></h5>
          <p className="inm-price"><b>{`$ ${formatter.format(price.value)} ${price.currency}`}</b></p>
        </div><div className="whatsapp-container">
                <a
                  href={`https://api.whatsapp.com/send?phone=2257553534&text=¡Hola! Estoy interesado en la propiedad con código ${propCode}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={wh} alt="WhatsApp" />
                </a>
              </div>
      </div> 
      <div className="inm-slider">
        <ProductSlider images={images} />
       
      </div>
         <div className="inm-slider" align="center">
         <iframe
                  className="publish-form-video-iframe"
                  src={`https://www.youtube.com/embed/${video_id}`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                  align="center"
                />
    </div>
      
      <div className="inm-placement">
        <div className="inm-body">
          <div className="inm-body-char">
            <DoCharList charList={characteristics} />
          </div>
          <div className="inm-description">
            <h2 className="inm-desc-title">Descripción</h2>
            <p className="inm-desc-text">{description}</p>
          </div>
          <div className="inm-att">
            <h2 className="inm-att-title">Prestaciones</h2>
            <div className="inm-body-att">
              <DoAttList attList={attributes} />
            </div>
          </div>
        </div>
       
          <div className="inm-agent-info">
            
            <div className="inm-agent-contact">
              <div >
                <h3 className="inm-agent-name"><b> {agent.username}</b></h3>
              </div>
              <div className="inm-agent-contact-container">
                <div className="inm-agent-contact-info">
                  <p className="inm-agent-text">Télefono:<b> {agent.phonenumber}</b></p>
                
                </div>
               
                <div className="inm-agent-contact-info">
                  <p className="inm-agent-text">Email:<b> {agent.email}</b> </p>
                  
                </div>
               
     
                <img
  src={logo}
  alt="ludmila rosas -logo"
  className="menu-image"
  style={{
    maxWidth: "100%", // Asegura que la imagen no sea más ancha que su contenedor
    height: "auto",    // Permite que la altura se ajuste automáticamente
    width: "300px" ,
    margin: "0 auto",    // Establece un ancho específico para achicar el logo
  }}
/>

      
              </div><div className="whatsapp-container">
                <a
                  href={`https://api.whatsapp.com/send?phone=2257553534&text=¡Hola! Estoy interesado en la propiedad con código ${propCode}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={wh} alt="WhatsApp" />
                </a>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default Propiedad;

const DoCharList = ({ charList }) => {

  return (
    <IconContext.Provider value={{ className: "inm-char-icons" }}>
      {Object.keys(charList).map(
        (key) =>
        charList[key] && (
            <div key={key} className="inm-body-char-div">
              <p className="inm-char-p">{key}</p>
              <div className="inm-char-info">
                <p className="inm-char-number">
                  {icons[key]}
                  {charList[key]}
                </p>
              </div>
            </div>
          )
      )}
    </IconContext.Provider>
  );
};

const DoAttList = ({ attList }) => {
  return (
    <IconContext.Provider value={{ className: "inm-feat-icons inm-feat-true" }}>
      {Object.keys(attList).map(
        (key) =>
          attList[key] && (
            <div key={key} className="inm-body-att-div">
              <GoCheck /> {key}
            </div>
          )
      )}
    </IconContext.Provider>
  );
};

var formatter = new Intl.NumberFormat("en-US", {});
