import React, { useEffect } from 'react';
import "./insta.css";import "../home/home.css";
const InstagramPost = () => {
  useEffect(() => {
    // Carga el script de Instagram después de que el componente se haya montado
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);

    return () => {
      // Elimina el script cuando el componente se desmonte para evitar problemas de carga múltiple
      document.body.removeChild(script);
    };
  }, []);

  return (<div className="contenedor">
    <div className="instagram-embed">
      <blockquote
        className="instagram-media"
        data-instgrm-captioned
        data-instgrm-permalink="https://www.instagram.com/p/CrJvEo_OcLM/?utm_source=ig_embed&amp;utm_campaign=loading"
        data-instgrm-version="14"
      >
      </blockquote></div> <div className="instagram-embed"><blockquote
        className="instagram-media"
        data-instgrm-captioned
        data-instgrm-permalink="https://www.instagram.com/p/CyExnhOOdsa/?utm_source=ig_embed&amp;utm_campaign=loading"
        data-instgrm-version="14"
      >
      </blockquote> </div> <div className="instagram-embed"><blockquote
        className="instagram-media"
        data-instgrm-captioned
        data-instgrm-permalink="https://www.instagram.com/p/CyExp7Pu_Za/?utm_source=ig_embed&amp;utm_campaign=loading"
        data-instgrm-version="14"
      >
      </blockquote></div>
    </div>
  );
};

export default InstagramPost;  